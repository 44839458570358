var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"exycPTP-mzbrkM7LCxrJd"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { isDevelopment } from '@lib/common';
import * as Sentry from '@sentry/nextjs';

!isDevelopment &&
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_APP_ENV,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,
    tracesSampleRate: 1,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
