export const isDevelopment = process.env.NEXT_PUBLIC_APP_ENV === 'development';
export const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';
export const isStaging = process.env.NEXT_PUBLIC_APP_ENV === 'staging';

export function delay(seconds = 1): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

export function openCalendly() {
  window.open(
    'https://calendly.com/emmikavander/matchinverse-profile-sparring',
    '_blank'
  );
}
